/*****  VARS  ****/

$darkGrey: #545454;
$darkerGrey: #343434;
$medGrey: #838383;
$lightGrey: #d1d1d1;
$hoverGrey: #b9b9b9;
$toxicGreen: #99ff0e;
$sectionHeader: #686868;

$mako: "Mako", sans-serif;

$sectionSize: 1.2rem;

/****  MIXINS  ****/

@mixin translateY($e) {
  -webkit-transform: translateY($e);
  -moz-transform: translateY($e);
  transform: translateY($e);
}

@mixin transition($e) {
  -webkit-transition: $e;
  -moz-transition: $e;
  transition: $e;
}

@mixin button {
  display: block;
  color: white;
  background-color: black;
  font-size: $sectionSize;
  text-align: center;
  padding: 5px;
  width: 115px;
  height: 40px;
  @include transition(0.4s ease-in-out);

  &:hover {
    text-decoration: none;
    background-color: $medGrey;
    color: white;
  }
}

// @mixin link1 {
// 	color: black;

// 	&:hover {
// 		text-decoration: none;
// 	}
// }

/****  EXTENDS  ****/

%artist-card {
  padding: 10px;
  color: black;
}

%link1 {
  color: black;

  &:hover {
    text-decoration: none;
  }

  div.card,
  div.whiteBox {
    @include transition(0.5s ease-in-out);

    &:hover {
      background-color: $hoverGrey;
    }
  }
}

/****  MAIN  ****/

body {
  background-color: $lightGrey;
  @include transition(1s ease-in-out);
}

body.white {
  background-color: #fff;
  @include transition(1s ease-in-out);
}

.sealBeachForm {
  width: 600px;
  height: auto;
}

// #content {
// 	padding-top: 4rem;
// }

.section-content {
  padding: 2.5rem 0;
}

@media screen and (max-width: 767px) {
  .section-content {
    padding: 1.5rem 0;
  }
}

.policyModal {
  font-size: 0.8rem;
}

button {
  @extend %link1;
  cursor: pointer;
  border: 0px;
  padding: 0px;
  background-color: transparent;
}

/****  NAVBAR  ****/

.nav-padding {
  /*min-height: 75rem;*/
  padding-top: 3.5rem;
}

.navbar,
.nav-box {
  background-color: $medGrey;
  height: 78px;
  border-bottom: 2px solid $darkGrey;
  padding: 0rem 2rem;

  img.logo-main {
    height: 36px;
    /* @include translateY(-50%); */
  }

  @media screen and (max-width: 767px) {
    img.logo-main {
      height: 30px;
    }
  }

  nav {
    display: flex;
    flex-direction: row;
  }

  button.navbar-toggler {
    align-self: center;
  }
}

@media screen and (max-width: 767px) {
  .navbar,
  .nav-box {
    height: 98px;
    @include transition(0.4s ease-in-out);
  }
}

a.nav-link,
a.nav-anchor {
  display: flex;
  // flex-direction: row;
  text-transform: uppercase;
  font-family: $mako;
  color: $lightGrey;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  padding-top: 1.5rem;
  height: 76px;
  @include transition(0.4s ease-in-out);

  &:hover {
    color: #fff;
  }
}

a.nav-link.active {
  color: #fff;
}

@media screen and (max-width: 767px) {
  a.nav-link,
  a.nav-anchor {
    font-size: 1rem;
    padding-top: 0.5rem;
    height: 46px;
  }
}

ul.navbar-nav a.active,
nav a.active {
  border-bottom: 6px solid $toxicGreen;
}

.nav-sublinks {
  width: 180px;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      margin-right: 1.5rem;
    }
  }

  div {
    background-color: #fff;
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .nav-sublinks div {
    display: none;
  }
}

/****  FOOTER  ****/

#footer-main {
  background-color: $darkerGrey;
  color: $medGrey;

  .row {
    align-items: center;
    height: 105px;

    p {
      margin-bottom: 0rem;

      button {
        color: $lightGrey;
      }
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
          margin-right: 1.3rem;

          .icon {
            height: 25px;
          }
        }
      }
    }
  }
}

/****  HOME  *****/

#shout-out {
  // background-color: #d1d1d1;

  a {
    @extend %link1;
  }

  .featured-card p.card-text {
    font-size: 1.3rem;
  }

  p.card-title {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
  }
}

// #studio-talk {
//   background: url("/img/home-bg_studio.jpg") center center no-repeat;
//   background-size: cover;
// }

.studio-text {
  background-color: #fff;
  padding: 1.875rem;
  min-height: 300px;

  h3 {
    font-family: $mako;
    font-size: 3rem;
  }
}

/*****  ARTISTS  ******/

#artists {
  a {
    @extend %link1;
  }

  .row {
    padding-bottom: 3rem;
  }

  .whiteBox {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .row:nth-child(even) .whiteBox {
    justify-content: space-between;
  }

  // .whiteBox.alt-justify {
  // 	justify-content: space-between;
  // }

  .row:nth-child(even) .whiteBox div:first-child {
    order: 1;
  }

  // .alt-justify div:first-child {
  // 	order: 1;
  // }

  .img-box {
    width: 540px;
  }

  .aboutArtist {
    padding: 20px;
    width: 470px;

    p.title {
      font-family: $mako;
      font-size: 3rem;
      margin-bottom: 0.3rem;
    }
  }

  @media screen and (max-width: 991px) {
    .aboutArtist {
      p.title {
        font-size: 2rem;
        margin-bottom: 0.3rem;
      }
    }
  }

  @media screen and (max-width: 770px) {
    .whiteBox {
      flex-direction: column;
      align-items: center;
    }

    .img-box {
      width: 100%;
    }

    .aboutArtist {
      width: 100%;
    }

    .row:nth-child(even) .whiteBox div:first-child {
      order: 0;
    }
  }
}

#artistFocusTop {
  // .row {
  // 	padding-bottom: 3rem;
  // }

  // .img-box {
  // 	width: 540px;
  // }

  .selectedReleases {
    margin-top: 40px;

    p {
      color: $sectionHeader;
      font-size: $sectionSize;
    }
  }

  @media screen and (max-width: 991px) {
    .selectedReleases {
      margin-top: 20px;
    }
  }

  .aboutArtist {
    p.title {
      font-family: $mako;
      font-size: 3rem;
    }
  }

  .featuredTracks {
    p {
      color: $sectionHeader;
      font-size: $sectionSize;
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        margin: 0px;
        padding: 0px 0px 15px 0px;
      }

      @media screen and (max-width: 991px) {
        li {
          padding: 0px 0px 5px 0px;
        }
      }
    }

    iframe {
      border: 0;
      width: 100%;
      height: 42px;
    }
  }
}

#artistFocusBottom {
  .mediaTitle {
    p {
      color: $sectionHeader;
      font-size: $sectionSize;
    }
  }

  img.media {
    padding-bottom: 40px;
  }
}

/*****  RELEASES  ******/

#releases {
  a {
    @extend %link1;
  }

  img[src*="SOL007"] ~ div p.card-text,
  img[src*="SOL004"] ~ div p.card-text {
    font-size: 0.9rem;
  }

  .card-body {
    padding: 0.75rem;

    p.card-title {
      font-size: 1.3rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0;
    }

    p.card-text {
      font-size: 1.1rem;
      font-weight: 100;
      text-align: center;
    }
  }
}

#releaseFocusTop {
  .streamAlbum {
    margin-top: 40px;

    p {
      color: $sectionHeader;
      font-size: $sectionSize;
    }
  }
  @media screen and (max-width: 991px) {
    .streamAlbum {
      margin-top: 20px;
    }
  }

  .releaseArtist {
    p {
      font-family: $mako;
      font-size: 1.7rem;
      margin-bottom: 0;
    }
  }

  .releaseTitle {
    p {
      font-family: $mako;
      font-size: 3rem;
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }
  }

  .details {
    p {
      color: $sectionHeader;
      font-size: $sectionSize;

      &:first-child {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }

  .buy {
    display: flex;
    justify-content: flex-end;

    a {
      @include button;
    }
  }

  .tracklist {
    font-size: $sectionSize;
    margin-bottom: 2rem;

    p {
      margin-bottom: 0px;
    }

    ol {
      // margin: 0px;
      padding-left: 22px;

      li {
        margin: 0px;
        padding: 0px 0px 2px 0px;
      }
    }
  }
}

#releaseFocusBottom {
  img.media {
    padding-bottom: 40px;
  }
}

/*****  MODAL ******/

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.modalContent {
  position: absolute;
  top: 10%;
  left: 30%;
  right: auto;
  bottom: auto;
  // inset: 100px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.close-button {
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;

  button {
    width: 28px;
    height: 28px;
  }

  .close-icon {
    width: 28px;
    height: 28px;
  }
}

/*****  EXTRAS  ******/

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.loading {
  height: 900px;
  width: 100%;

  div.action {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    border: 8px solid $lightGrey;
    border-radius: 50%;
    border-top: 8px solid $toxicGreen;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
  }

  div.text {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 0.8rem;
    width: 100px;
    color: $medGrey;
    animation: fadeInOut 6s infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/****** OVERRIDES *****/

.navbar-collapse {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.card {
  border-radius: 0;
  border: 0px;
}

.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/******** animations ********/

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated.fadeIn {
  /*-ms-animation-name: fadeIn;*/
  animation-name: fadeIn;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animated.fadeInOut {
  /*-ms-animation-name: fadeIn;*/
  animation-name: fadeIn;
}

.pageIn {
  animation-duration: 1s;
  animation-name: pageIn;
}

@keyframes pageIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
